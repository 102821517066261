import React, { useEffect, useState } from 'react'
import { Col } from "react-bootstrap";
import { Form, Input, Button, Select } from "../../../components";
import { axios } from "../../../boot";
import { rules } from "../../../constants";
import { convertPhone, objectSelect, toast } from "../../../methods";
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const EditProfile = () => {
    const [data, setData] = useState({})
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const profile = useSelector((s) => s.profile);
    const setProfile = (data = {}) => {
        dispatch({ type: "SET_PROFILE", data });
    };
    const gender = [
        { name: "زن", id: "male" },
        { name: "مرد", id: "female" },
    ]
    const formControls2 = [
        {
            label: "نام",
            labelColor: "white",
            border: true,
            borderColor: "jet",
            state: "firstName",
            rules: rules.notEnglishKeyboard,
        },
        {
            label: "نام خانوادگی",
            labelColor: "white",
            border: true,
            borderColor: "jet",
            state: "lastName",
            rules: rules.notEnglishKeyboard,
        },
        {
            label: "ایمیل",
            state: "email",
            rules: rules.email,
            labelColor: "white",
            border: true,
            borderColor: "jet",
        },
        {
            label: "کدملی",
            state: "codeMelli",

            rules: rules.nationalId,
            labelColor: "white",
            border: true,
            borderColor: "jet",
        },
        {
            label: "شغل",
            state: "job",
            labelColor: "white",
            border: true,
            borderColor: "jet",
            rules: rules.notEnglishKeyboard,
        },
        {
            label: "درآمد دلاری",
            state: "inventory",
            labelColor: "white",
            border: true,
            borderColor: "jet",
        },
        {
            label: "تاریخ تولد",
            state: "birthday",
            type: "date",
            labelColor: "white",
            border: true,
            borderColor: "jet",
        },
        {
            tag: Select,
            as: Input,
            items: gender,
            label: "جنسیت",
            state: "gender",
            labelColorr: "white",
            border: false,
            borderColor: "jet",
        },
        {
            label: `شماره موبایل ${profile?.phone?.length > 1 ? "(غیرقابل تغییر)" : ""}`,
            labelColor: "white",
            border: true,
            borderColor: "jet",
            state: "phone",
            rules: rules.optional,
            readOnly: profile?.phone?.length > 1 ? true : false,
            dir: "ltr"
        },
    ];
    const submit = () => {
        const keys = formControls2.map((e) => e.state);
        const url = "/users/authentication";
        const body = objectSelect(data, keys);
        body._id = data._id;
        if (profile?.phone?.length > 1) delete body.phone;
        axios.put(url, body).then(() => {
            toast({});
            setProfile({ ...profile, ...body });
            navigate("/profile")
        });
    };
    useEffect(() => setData(profile), [profile]);
    return (
        <div className="bg-dark-black py-4">
            <Form onSubmit={submit} className="row justify-content-start">
                {formControls2.map((e, index) =>
                    <Col key={index} xs="12" md="6" lg="4">
                        {React.createElement(e.tag ?? Input, {
                            ...e,
                            key: e.state,
                            value: data[e.state],
                            className: "bg-raisin2 text-white rounded-4 fs-14 fw-400",
                            setValue: (val) => setData((p) => ({ ...p, [e.state]: val })),
                        })}
                    </Col>
                )}
                <div className="d-flex justify-content-start flex-wrap align-items-center my-4">
                    <Link to="/profile" className="btn btn-outline-danger ">لغو ویرایش</Link>
                    <Button className='mx-2' outline={true} type="submit">ذخیره اطلاعات</Button>
                </div>
            </Form>
        </div>
    )
}

export default EditProfile