import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import cloneDeep from "lodash/cloneDeep";
import { Col, ButtonGroup } from "react-bootstrap";
import { rules, roles, authenticationStatus } from "../../../constants";
import { convertPhone, toast } from "../../../methods";
import { Form, AdminInput, Select, Button } from "../../../components";
import { axios } from "../../../boot";
import { Link } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";

export default function User() {
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const gender = [
    { name: "زن", id: "male" },
    { name: "مرد", id: "female" },
]
  const isNewUser = params.userId === "new";
  const fromControls = [
    {
      label: "نام",
      state: "firstName",
    },
    {
      label: "نام خانوادگی",
      state: "lastName",
    },
    {
      label: "ایمیل",
      state: "email",
    },
    {
      label: "شغل",
      state: "job",
      rules : rules.optional
    },
    {
      label: "کدملی",
      state: "codeMelli",
      rules : rules.optional
    },
    {
      label: "درآمد دلاری",
      state: "inventory",
      rules : rules.optional
    },
    {
      label: "تاریخ تولد",
      state: "birthday",
      rules : rules.optional,
      type : "date"
    },
    {
      label: "شماره موبایل",
      type: "number",
      state: "phone",
      dir: "ltr",
      rules: rules.phoneNumber,
    },
    {
      tag: Select,
      as : AdminInput,
      label: "وضعیت احراز هویت",
      state: "authentication",
      items: authenticationStatus,
    },
    {
      tag: Select,
      as : AdminInput,
      label: "جنسیت",
      state: "gender",
      items: gender,
    },
  ];
  const getData = () => {
    if (!isNewUser) {
      const url = `/admins/users/${params.userId}`;
      axios.get(url).then(({ data }) => {
        const body = cloneDeep(data);
        body.phone = convertPhone(data.phone);
        setData(body);
      });
    }
  };
  const addNewUser = () => {
    const url = "/admins/users";
    const body = cloneDeep(data);
    body.phone = convertPhone(data.phone, true);
    axios.post(url, body).then(() => {
      toast({});
      navigate("/admin/users", { replace: true });
    });
  };
  const changeUser = () => {
    const url = "/admins/users";
    const body = cloneDeep(data);
    body.phone = convertPhone(data.phone, true);
    delete body.createdAt;
    delete body.password;
    delete body.updatedAt
    delete body.role
    delete body.birthdayJalali
    body.id = data?._id
    axios.put(url, body).then(() => {
      let text = "اطلاعات با موفقیت ویرایش شد"
      toast({text});
      navigate("/admin/users")
    });
  };
  const deleteUser = () => {
    const message = "آیا از درخواست خود اطمیمان دارید؟";
    if (window.confirm(message)) {
      const url = "/admins/users";
      const body = { _id: params.userId };
      axios.delete(url, { data: body }).then(() => {
        toast({});
        navigate("/admin/users", { replace: true });
      });
    }
  };

  useEffect(getData, []);
  return (
    <Form onSubmit={isNewUser ? addNewUser : changeUser} className="row">
      <div className="w-100 d-flex align-items-center justify-content-end">
          <Link to="/admin/users"><IoIosArrowRoundBack size={25}/></Link>
      </div>
      <h5 className="text-center col-12">
        {isNewUser ? "اضافه کردن کاربر جدید" : "اطلاعات کاربر"}
      </h5>
      {fromControls.map((props, index) => (
        <Col key={index} xs="12" lg={6}>
          {React.createElement(props.tag ?? AdminInput, {
            ...props,
            value: data[props.state],
            setValue: (val) => setData((p) => ({ ...p, [props.state]: val })),
          })}
        </Col>
      ))}
      <div className="col-12 d-flex flex-center">
        <ButtonGroup>
          {!isNewUser && (
            <Button variant="danger" onClick={deleteUser}>
              حذف
            </Button>
          )}
          <Button type="submit">ثبت</Button>
        </ButtonGroup>
      </div>
    </Form>
  );
}
