import React, { useCallback, useEffect, useState } from "react";
import { authenticationStatus } from "../../../constants";
import { AdminInput, Pagination, Select, Table } from "../../../components";
import { axios, moment } from "../../../boot";
import { convertPhone, jsonToXlsx, scrollToTop } from "../../../methods";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";


export default function Users() {
  const basicParams = JSON.parse(sessionStorage.getItem("params")) ?? {};
  const navigate = useNavigate()
  const [params, setParams] = useState({ page: 1, ...basicParams });
  const [users, setUsers] = useState([]);
  const [pages, setPages] = useState({});
  const saveParams = () => {
    const value = JSON.stringify(params);
    sessionStorage.setItem("params", value);
  };
  const getUsers = () => {
    saveParams();
    const url = "/admins/users";
    const config = {
      params: {
        sort: "createdAt:-1",
        perPage: 20,
        ...params,
      },
    };
    // params.date &&
    //   ([config.params.startDate, config.params.endDate] = params.date);
    delete config.params.date;
    config.params.phone && (config.params.phone = convertPhone(params.phone))
    axios.get(url, config).then(({ data }) => {
      setUsers(data.data);
      setPages(data.pages);
      scrollToTop();
    });
  };
  const downloadXlsx = async () => {
    const url = "/admins/users";
    const perPage = 100;
    const totalPages = Math.ceil(pages.totalItems / perPage);
    const config = (page) => ({ params: { ...params, perPage, page, } });
    const getAllData = await [...Array(totalPages)]
      .map((e, i) => i + 1)
      .map(
        async (page) => await (await axios.get(url, config(page))).data?.data
      );
    const allData = await Promise.all(getAllData);
    const title = "report";
    const json = allData.flat().map((e) => ({
      "نام ": `${e?.firstName}`,
      "نام خانوادگی ": `${e?.lastName}`,
      "کد ملی": `${e?.codeMelli ? e?.codeMelli : ""}`,
      "شغل": `${e?.job ? e?.job : ""}`,
      "ایمیل": `${e?.email ? e?.email : ""}`,

      "درآمد دلاری": `${e?.inventory ? e?.inventory : ""}`,
      "شماره موبایل": `${convertPhone(e?.phone)}`,
      "تاریخ تولد": moment.miladiToShamsi({ date: e.birthday }),
      وضعیت: authenticationStatus.find((it) => it.id === e.authentication)?.name,
    }));
    jsonToXlsx({ json, title });
  };
  const formControls = [
    {
      label: "نام",
      state: "firstName",
      type: "text",
      clear: true,
    },
    {
      label: "نام خانوادگی",
      state: "lastName",
      type: "text",
      clear: true,
    },
    {
      label: "شماره تماس",
      state: "phone",
      type: "number",
      clear: true,
    },
    {
      label: "ایمیل",
      state: "email",
      clear: true,
    },

    {
      tag: Select,
      as : AdminInput,
      label: "وضعیت احراز هویت",
      state: "authentication",
      cancelBtn: true,
      items: authenticationStatus,
    }
  ];

  const showStatus = (id = 0) => {
    const { color, name } = authenticationStatus.find((e) => e.id === id) ?? {};
    return <span className={`text-${color} fw-bold`}>{name}</span>;
  };
  const handleClick = (id) => {
    navigate(`/admin/users/${id}`)
  }
  useEffect(getUsers, [params]);
  return (
    <React.Fragment>
      <div className="d-print-none">
        <button
          className="bi bi-filetype-xlsx fs-3 text-success"
          onClick={downloadXlsx}
        />
      </div>
      <Row className="d-print-none">
        {formControls.map((e) => (
          <Col key={e.state} xs="12" md="6" lg="4">
            {React.createElement(e.tag ?? AdminInput, {
              ...e,
              value: params[e.state],
              setValue: (val) => {
                setUsers([]);
                setParams((p) => ({ ...p, page: 1, [e.state]: val }));
              },
            })}
          </Col>
        ))}
      </Row>

      <Table className="d-print-none">
        <thead>
          <tr>
            <th>نام و نام خانوادگی</th>
            <th>شماره تلفن</th>
            <th>شغل</th>
            <th>کدملی</th>
            {/* <th>تاریخ تولد</th> */}
            <th>وضعیت احراز هویت</th>
          </tr>
        </thead>
        <tbody>
          {users?.map((e, i) => (
            <tr onClick={() => handleClick(e?._id)} key={`users-${e?._id}`}  >
              <td onClick={(i) => i.stopPropagation()}>
                {e?.firstName} {e?.lastName}
              </td>
              <td onClick={(i) => i.stopPropagation()}>{convertPhone(e?.phone)}</td>
              <td>{e.job}</td>
              <td>{e.codeMelli}</td>
              <td>{showStatus(e.authentication)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination

        totalPages={pages.totalPages}
        activePage={params.page}
        setActivePage={(page) => setParams((p) => ({ ...p, page }))}
      />
      {/* {params.page < pages.totalPages && (
        <button
          className="d-block mx-auto w-fit text-primary"
          onClick={() => {
            setParams((p) => ({ ...p, page: p.page + 1 }));
          }}
        >
          نمایش بیشتر...
        </button>
      )} */}
    </React.Fragment>
  );
}
