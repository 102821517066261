const authenticationStatus = [
    {
        name: "در انتظار احراز هویت",
        id: 0,
        color: "warning",
        filterable: true,
    },
    {
        name: "در انتظار بررسی",
        id: 1,
        color: "primary",
        filterable: true,
    },
    {
        name: "تایید شده",
        id: 2,
        color: "success",
        filterable: true,
        isUpdateStatus: true,
    },
    {
        name: "رد شده",
        id: 3,
        color: "danger",
        filterable: true,
    },
];
export default authenticationStatus;
